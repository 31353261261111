import axios from 'axios';
import { pcOrderListUri } from '@/components/common/cart-finished-constant';
import { renderState } from '@/helpers/vuex';

const maxWaitTimes = 1000 * 60 * 22;
const timeoutTimes = 5 * 1000;

const pollFetchData = (totalWaitTimes) => {
  const pollingUrl = renderState('OrderFinishInfo', 'plusPayPollingUrl');
  axios
    .get(pollingUrl)
    .then((response) => {
      const { data } = response;
      const fetchSuccess = 1;
      if (data.code === fetchSuccess) {
        const execScript = renderState('OrderFinishInfo', 'execScript');
        const elem = document.createElement('script');
        elem.innerHTML = execScript;
        document.body.appendChild(elem);
        return;
      }
      const fetchPollingState = 2;
      if (data.code === fetchPollingState) {
        // eslint-disable-next-line no-use-before-define
        runPollLoop(totalWaitTimes);
        return;
      }
      window.location.href = pcOrderListUri;
    })
    // eslint-disable-next-line no-use-before-define
    .catch(() => runPollLoop(totalWaitTimes));
};

const runPollLoop = (totalWaitTimes) => {
  totalWaitTimes += timeoutTimes;
  if (totalWaitTimes <= maxWaitTimes) {
    setTimeout(() => pollFetchData(totalWaitTimes), timeoutTimes);
    return;
  }
  window.location.href = pcOrderListUri;
};

export const onReadyFetch = () => {
  runPollLoop(-timeoutTimes);
};
