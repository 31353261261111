<template>
  <div class="rwd-form">
    <div class="wrapper">
      <div class="form-fieldset loading-wrapper">
        <h3 class="form-signup-title">使用全盈+PAY 掃碼付款</h3>
        <div class="text-center" v-if="qrCode">
          <vue-qrcode :value="qrCode" :options="{ width: 250 }"></vue-qrcode>
        </div>
        <p class="spacing top stackable text-center">
          使用全盈+PAY付款前，請先開通申請其帳號，並依其操作流程進行交易，詳情請參考全盈支付官網說明。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { getState } from '@/helpers/vuex';
import { onReadyFetch } from '@/helpers/pluspay-qrcode';

const qrCode = getState('OrderFinishInfo', 'plusPayQrCode');

export default {
  name: 'pluspay',
  components: {
    VueQrcode,
  },
  computed: {
    qrCode,
  },
  mounted() {
    onReadyFetch();
  },
};
</script>
